import { Alert, Card, Col, Row } from 'react-bootstrap';
import { useContext, useEffect, useState } from 'react';
import studentsImage from '../../../images/students.JPG';
import useWithLoading from '../../../hooks/useWithLoading';
import { fetchPriceForEvent } from '../../../services/events';
import { AuthContext } from '../../../context/AuthContextProvider';
import Loading from '../../common/Loading';
import { ALERT_ERROR_MESSAGE } from '../../../utils/ResponseUtils';
import { PAYMENT_METHODS } from '../../../configs';
import SingleSelectInputFromList from '../common/SingleSelectInputFromList';
import { BundleContext } from '../../../context/BundleContextProvider';

function PaymentMethodSelection() {
  const { price, computePriceLoading } = useContext(BundleContext)

  return (<Card className={"p-3"}>
      <Row>
        <span><h1 className="lead fw-bold mb-3">Payment Summary</h1></span>
        <span className="text-end h1"><span className="text-end lead">Total: </span><Loading
          loading={computePriceLoading}>${price.total}{' '}NZD</Loading></span>
      </Row>
      {
        price.total !== 0 &&
        <SingleSelectInputFromList label="Choose method of payment" field="paymentMethod" data={PAYMENT_METHODS} required/>
      }
    </Card>
  );
}

export default PaymentMethodSelection;
