import { useParams } from 'react-router-dom';
import { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { Button } from 'react-bootstrap';

const AddonBuilder = ({addAddonToForm}) => {
  const [json, setJson] = useState({})

  return <Form onSubmit={(e) => {
    e.preventDefault()
    json.isDeliveryRequired = !!json.isDeliveryRequired
    addAddonToForm(json)
  }}>
    <Form.Group>
      <Form.Label column={"xs"}>Addon Name* (eg. printed certificate)</Form.Label>
      <Form.Control required type={"text"} onChange={e=>setJson({...json, label: e.target.value})} />
    </Form.Group>
    <Form.Group>
      <Form.Label column={"xs"}>Price*</Form.Label>
      <Form.Control required type={"number"} onChange={e=>{
        setJson({...json, price: e.target.value})
      }} />
    </Form.Group>
    <br/>
    <Form.Check
      type="switch"
      label={"Requires delivery"}
      onChange={e => setJson({...json, isDeliveryRequired: e.target.checked})}
    />
    <br/>
    <Button type={"submit"}>Append to form</Button>
  </Form>
}

export default AddonBuilder
