import React, { createContext, useContext, useEffect, useState } from 'react';
import useWithLoading from '../hooks/useWithLoading';
import { getStudentsOfSchool } from '../services/schools';
import { ALERT_ERROR_MESSAGE } from '../utils/ResponseUtils';
import { AuthContext } from './AuthContextProvider';
import { fetchPriceForEvent } from '../services/events';

const DEFAULT_BUNDLE = {
  delivery: {},
  addons: {},
}

const DEFAULT_PRICE = {
  defaultPrice: null,
  discountedPrice: null,
  total: null
}

const BundleContext = createContext({
  price: DEFAULT_PRICE,
  computePrice: (eventCode, bundle, studentEmail) => {},
  computePriceLoading: false,
  bundle: DEFAULT_BUNDLE,
  clearBundle: () => {},
  setDelivery: (isCustomAddress, address) => {},
  addAddon: (addonKey, price, isDeliveryRequired) => {},
  removeAddon: (addonKey) => {}
});

/**
 * This should only be used in the context of Payment bundle (if you don't know what that means, don't use it)
 * @param children
 * @returns {Element}
 * @constructor
 */
function BundleContextProvider({ children }) {
  const [bundle, setBundle] = useState(DEFAULT_BUNDLE);
  const [price, setPrice] = useState(DEFAULT_PRICE);
  const [computePriceLoading, withLoading] = useWithLoading();
  const {authRetrievedProfile: auth} = useContext(AuthContext)

  const clearBundle = () => setBundle(DEFAULT_BUNDLE);
  const setDelivery = (isCustomAddress, address) => {
    bundle.delivery = {
      isCustomAddress,
      address
    }
    setBundle({...bundle})
  }
  const addAddon = (addonKey, price, isDeliveryRequired) => {

    bundle.addons[addonKey] = {
      price,
      isDeliveryRequired
    };
    const newBundle = {...bundle}
    setBundle(newBundle)
  }

  const removeAddon = (addonKey) => {
    delete bundle.addons[addonKey]
    setBundle({...bundle})
  }

  const computePrice = (eventCode)=>{
    withLoading(
      fetchPriceForEvent(eventCode, bundle, auth.email),
      (res) => setPrice(res?.data),
      ALERT_ERROR_MESSAGE
    )
  }


  return (
    <BundleContext.Provider value={{
      bundle, clearBundle, setDelivery, addAddon, removeAddon,
      price, computePrice, computePriceLoading
    }}>
      {children}
    </BundleContext.Provider>
  );
}

export { BundleContext, BundleContextProvider };
