import { Card, Col } from 'react-bootstrap';
import { useContext } from 'react';
import { BundleContext } from '../../../context/BundleContextProvider';
import Form from 'react-bootstrap/Form';

const BundleSelection = ({addons})=>{
  if(Object.keys(addons).length === 0){
    return <></>
  }

  const Selections = ()=>{
    const boxes = [];
    for (const addonKey in addons){
      const {isDeliveryRequired, price} = addons[addonKey]
      boxes.push(<AddonTickBox addonKey={addonKey} isDeliveryRequired={isDeliveryRequired} price={price} />)
    }
    return boxes;
  }

  return (<>
    <Card className={'p-3'}>
      <Col xs={12}><h1 className="lead fw-bold mb-3">Addons</h1></Col>
      <Selections/>
    </Card>
    <br/>
  </>)
}

const AddonTickBox = ({addonKey, isDeliveryRequired, price})=>{
  const { bundle, addAddon, removeAddon } = useContext(BundleContext);

  const onTick = e => {
    if (e.target.checked) {
      addAddon(addonKey, price, isDeliveryRequired)
    } else {
      removeAddon(addonKey)
    }
  }

  const boxCurrentlyTicked = addonKey in bundle.addons

  const text = ()=>{
    return (
      <>
        {isDeliveryRequired && <i className="bi bi-truck me-1"/>}{addonKey}{` (+$${price})`}
      </>
    )
  }

  return (
    <Form.Check type={"switch"} id={addonKey} label={text()} onChange={onTick} checked={boxCurrentlyTicked} />
  )
}



export default BundleSelection;
